import { computed, ref } from 'vue';
import { cdekConsoleError } from '@/utils/console-wrapper';
import { useStore } from 'vuex';
import LoginModal from '@/components/Login/LoginModal.vue';
import useModal from '@/composables/use-modal';
import { useRestApi } from '@/composables/use-rest-api';

type PrepareLoginCallback = (notificationText: string, alert: unknown, allowLogin: boolean) => void;

interface IOnLogin {
  defaultNotificationText?: string;
  isRedirect?: boolean;
  withLoadUser?: boolean;
  onSuccess?: () => void;
}

/**
 * Взял логику из components/Login/Login.mixin.ts и перевел в композбл
 * Убавил if>if>if>if...
 * gql -> rest
 */
export default function useLogin(legalOnly = false) {
  const store = useStore();
  const { restApi } = useRestApi();
  const modal = useModal();

  const isChineSite = computed<boolean>(() => store.getters.isChineSite);
  const currentLocaleISO = computed<string>(() => store.getters.currentLocaleISO);
  const isLoading = ref(false);

  const prepareDataLogin = async (callback: PrepareLoginCallback, notificationText = '') => {
    if (isChineSite.value) {
      window.location.href = 'https://lnx.cdek-express.cn/';
      return;
    }

    isLoading.value = true;
    let alert = null;
    let allowLogin = true;

    try {
      const { success } = await restApi.healthCheckNetwork('keycloak');

      if (!success) {
        notificationText =
          'Ведутся технические работы, приносим свои извинения. Попробуйте авторизоваться позже.';
        allowLogin = false;
      }
    } catch (e) {
      cdekConsoleError(e);
    }

    if (notificationText) {
      isLoading.value = false;
      callback(notificationText, alert, allowLogin);
      return;
    }

    try {
      const res = await restApi.websiteComponentAlert({
        locale: currentLocaleISO.value,
        url: '/login-modal-alert/',
      });

      if (!res || !res.length) {
        return;
      }

      const alerts = res[0];

      if (!alerts || !alerts.params?.items || alerts.params?.items.length === 0) {
        return;
      }

      alert = alerts.params.items[0];
      const { title } = alert;
      const newTitle = title.replace('{blocking}', '');
      allowLogin = title === newTitle;
      alert.title = newTitle;
    } catch (e) {
      cdekConsoleError(e);
    } finally {
      isLoading.value = false;
      callback(notificationText, alert, allowLogin);
    }
  };

  const onLogin = ({
    defaultNotificationText = '',
    isRedirect = true,
    withLoadUser = false,
    onSuccess = () => {},
  }: IOnLogin = {}) => {
    prepareDataLogin((notificationText, alert, allowLogin) => {
      modal.show({
        component: LoginModal,
        bind: {
          notificationText,
          alert,
          allowLogin,
          isRedirect,
          withLoadUser,
          legalOnly,
        },
        on: {
          success: onSuccess,
        },
      });
    }, defaultNotificationText);
  };

  return {
    prepareDataLogin,
    isLoading,
    onLogin,
  };
}
